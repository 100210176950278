'use strict';

angular.module('kljDigitalLibraryApp.auth')
    .service('InfoService', ["$http",
        function($http) {

            var info;

            return {
                getInfo: getInfo,
                setInfo: setInfo
            };

            function getInfo() {
                return info;
            }

            function setInfo(value) {
                info = value;
            }
        }
    ]);